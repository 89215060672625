<template>
  <div class="container">
    <!-- 卡片 -->
    <el-card class="box-card">
      <el-table :data="tableData" border stripe style="width: 100%" v-loading="loading">
        <el-table-column align="center" label="用户ID" prop="userId"></el-table-column>
        <el-table-column align="center" label="学生姓名" prop="pupilName"></el-table-column>
        <el-table-column align="center" label="学生手机" prop="pupilPhone"></el-table-column>
        <el-table-column align="center" label="家长姓名" prop="familyName"></el-table-column>
        <el-table-column align="center" label="家长手机" prop="familyPhone"></el-table-column>
        <el-table-column align="center" label="创建时间" prop="createTime"></el-table-column>
        <el-table-column align="center" label="状态">
          <template slot-scope="scope">
            <el-tag :type="scope.row.status == 0 ? 'danger' : 'success'" disable-transitions>
              {{ scope.row.status == 0 ? '无效' : '有效' }}
            </el-tag>
          </template>
        </el-table-column>
      </el-table>
    </el-card>
  </div>
</template>

<script>
import {signList} from '../../apis/course'

export default {
  data() {
    return {
      tableData: [],
      query: {
        courseId: '',
        id: ''
      },
      loading: ''
    }
  },
  activated() {
    const {courseId, id} = this.$route.query
    this.query.courseId = courseId
    this.query.id = id
    this.getData()
  },
  methods: {
    async getData() {
      this.loading = true
      const res = await signList(this.query)
      this.tableData = res.data
      setTimeout(() => {
        this.loading = false
      }, 50)
    }
  }
}
</script>

<style>
</style>